$(function() {
  if($("#kxmegane").is(':visible')) {
    const debugMode = false;

    /**
     * 初期化処理
     */
    function init() {
      // 初期化処理
      questions = [];
      $.each(querstionData, function(index, value) {
        questions.push([value.TEXT, 0, false]);
      });

      favoriteQuestions = [];
      companyName = '';
      idx = 0;

      // エラー文削除
      $("#question-error").html("");
      $("#question-error2").html("");

      // ボタンを表示
      $("#btnFin").hide();
      $("#btnNext").show();

      // チェックボックスのリセット
      $("input[name=answer]:checked").prop("checked",false);
    }

    // TOPのボタン処理
    $("#kxmegane_btn_top").on("click", function(){
      $(".flash").empty();
      $("#kxmegane_top").addClass('hidden').removeClass('js_view');
      $("#kxmegane_page1").addClass('js_view').removeClass('hidden');
    })

    // ガイダンスのボタン処理
    $("#kxmegane_btn_start").on("click", function(){
      $("#kxmegane_page1").addClass('hidden').removeClass('js_view');
      // 問題の初期化
      init();
      // 問題の表示
      showQuestion();
      $("#kxmegane_page2").addClass('js_view').removeClass('hidden');
    })

    /**
     * 問題表示
     */
    function showQuestion() {
      // プログレスバーの設置
      var bar_width = 100/40 * (idx);
      $("#kxmegane_question-bar").css("width", bar_width + "%");

      // 質問の設置
      $("#kxmegane_question-q").html('Q' + (idx+1) + '. ' + questions[idx][0]);
    }

    // チェックボックスのチェック時の処理
    $("input[name=answer]").change(function(){
      $("input[name=answer]").prop("checked", false);
      $(this).prop("checked", true);
      $(this).next('label').addClass('kxmegane-label-blink');
      if(idx < questions.length-1){
        // 最後の問題以外は次の設問へいく
        nextQuestion(true);
      }
    })

    // 次へ
    $("#kxmegane_btn_next").on("click", function(){
      nextQuestion(false);
    });

    /**
     * index番号を次の番号にして問題表示。
     * 最後の場合は結果表示
     */
    function nextQuestion(animation) {
      if(!selectCheck()){
        // チェックしていない
        return false;
      }
      let blinkSecond = 0;
      let changeSecond = 0;
      if (animation === true && debugMode === false){
        blinkSecond = 1;
        changeSecond = 1;
      }
      // チェックボックスの値を設定
      setCheckboxPoint();

      // indexを次へ
      idx++;

      if(idx == questions.length){
        // 大切な質問選択画面を表示
        showFavoriteQuestion();
      }else{
        // 連打対応のため位置的にクリック無効
        $("input[name=answer],label").css('pointer-events', 'none');
        // 最後の問題
        if(idx == questions.length - 1){
          //次へを非表示
          $("#kxmegane_btn_next").removeClass('js_view').addClass('hidden');
          // 自己診断ページへボタンを表示
          $("input[name=answer]").change(function(){
            $("#kxmegane_btn_fin_wrap").addClass('js_view').removeClass('hidden');
          });
        }
        window.setTimeout(function(){
          $("#kxmegane_question-wrap").addClass("kxmegane-page-blink");
          window.setTimeout(function(){
            // チェックボックスのリセット
            checkedCheck();
            // 次の問題
            showQuestion();
            // クリック有効に
            $("input[name=answer],label").css('pointer-events', '');
          }, changeSecond);
        }, blinkSecond);
      }
      if (debugMode === true){
        $('.result').html(questions);
      }
    }

    // 前へ
    $("#kxmegane_btn_prev").on("click", prevQuestion);

    /**
     * index番号を前の番号にして問題表示。
     * 最初の場合はガイダンス表示
     */
    function prevQuestion() {
      // indexを前へ
      idx--;

      if(!(idx == questions.length - 1)){
        // ボタン表示
        $("#kxmegane_btn_fin_wrap").addClass('hidden').removeClass('js_view');
        $("#kxmegane_btn_next").addClass('js_view').removeClass('hidden');
      }

      if(idx < 0){
        // ガイダンスページへ
        $("#kxmegane_page2").addClass('hidden').removeClass('js_view');
        $("#kxmegane_page1").addClass('js_view').removeClass('hidden');
      }else{
        // チェックボックスのリセット
        checkedCheck();
        // 問題表示
        showQuestion();
      }
    }

    /**
     * 戻るを押したときにチェックも戻す
     */
    function checkedCheck(){
      var row = questions[idx];
      var checedVal = row[1];
      if (checedVal > 0){
        $("input[name=answer]").each(function(){
          var val = $(this).val();
          if (checedVal == val){
            $(this).prop("checked", true);
          } else {
            $(this).prop("checked", false);
          }
        });
      } else {
        $("input[name=answer]:checked").prop("checked", false);
      }
      $(".kxmegane-label-blink").removeClass("kxmegane-label-blink");
      $("#kxmegane_question-wrap").removeClass("kxmegane-page-blink");
    }

    /**
     * セレクトの値をチェックする
     */
    function selectCheck() {
      // エラー文削除
      $("#kxmegane_question-error").html("");
      var is_check = false;
      $("input[name=answer]").each(function(){
        if($(this).prop("checked")){
          is_check = true;
        }
      })
      if(!is_check){
        // エラー文表示
        $("#kxmegane_question-error").html("選択してください。");
      }
      return is_check;
    }

    /**
     * チェックボックスの値を設定
     */
    function setCheckboxPoint() {
      questions[idx][1] = parseInt($("input[name=answer]:checked").val());
    }

    // 自分にとって大切な質問へ遷移
    $("#btn-2-2").on("click", function(){
      $("#kxmegane_page2-2").addClass('hidden').removeClass('js_view');
      nextQuestion("false");
    });

    /**
     * 大切な質問選択画面を表示
     */
    function showFavoriteQuestion() {
      $('#favorite-question-container').empty();

      answers = $.map(questions, function(question, index) {
        return question[1];
      });

      $.each(querstionData, function(index, question) {
        $('#favorite-question-container').append(`<div style="margin-bottom:15px"><input type="checkbox" id="question${index}" data-question-number=${index}><label for="question${index}">${question.TEXT}</label></div>`);
        let targetIndex = answers[index - 1];
        let answerNames = {
          '1': 'その通り！',
          '2': 'まあそうかな',
          '3': 'そうとはいえない',
          '4': '全く違う！',
        };
        for (answerIndex = 1; answerIndex <= 4; answerIndex++) {
          let checkedString = (targetIndex == answerIndex ? 'checked' : '');
          let indexAndAnswerIndex = index + '_' + answerIndex
          let html = '<input type="radio" id="answer' + indexAndAnswerIndex + '" style="margin-left:10px;" name="answer' + index +
              '" value="' + answerIndex + '" ' + checkedString +
              ' /><label for="answer' + indexAndAnswerIndex + '">' + answerNames[answerIndex] + '</label>'
          $('#favorite-question-container').append(html);
        }
        $('#favorite-question-container').append(`<br><br><br>`);
      });

      // コンテンツ表示
      $("#kxmegane_page2").addClass('hidden').removeClass('js_view');
      $("#kxmegane_page3").addClass('js_view').removeClass('hidden');
    }

    //自分にとって大切な質問の選択数上限設定
    $(document).on("change", function(){
      var element = 'input[data-question-number]'
      if ($(element + ':checked').length >= 5) {
        $(element + ':not(:checked)').prop("disabled", true);
      } else if ($(element + ':checked').length < 5) {
        $(element).prop("disabled", false);
      }
    });

    // 戻る
    $("#kxmegane_btn_prev3to2").on("click", function(){
      //コンテンツ表示
      $("#kxmegane_page3").addClass('hidden').removeClass('js_view');
      $("#kxmegane_page2").addClass('js_view').removeClass('hidden');

      prevQuestion();
    });

    // 回答終了画面へ遷移
    $("#kxmegane_btn_fin").on("click", function(){
      $("#kxmegane_page2").addClass('hidden').removeClass('js_view');
      $("#kxmegane_page2-2").addClass('js_view').removeClass('hidden');
    });

    // 会社名の入力画面へ遷移
    $("#kxmegane_btn_to_company").on("click", function(){
      element = $('input[data-question-number]:checked')

      if (element.length == 0) {
        $("#kxmegane_question-error2").html("｢自分にとって大切｣なものを1つ以上選択してください。");
        window.scroll({top: 0, behavior: 'smooth'});
        return;
      };

      element.map(function(){
        favoriteQuestions.push($(this).data("question-number"));
      });

      answers = []

      $('input[id^="answer"]:checked').map(function(){
        answers.push($(this).attr('id').match(/_(.*)/)[1]);
      });

      $.each(answers, function(index, answer) {
        html = `<input type="hidden" name="kxmegane[answers][]" value="${answer}">`
        $('.kxmegane_submit').append(html);
      });

      $.each(favoriteQuestions, function(index, favoriteQuestion) {
        html = `<input type="hidden" name="kxmegane[favorite_questions][]" value="${favoriteQuestion}">`
        $('.kxmegane_submit').append(html);
      });

      showCompanyName();
    });

    function showCompanyName() {
      $("#company_name_form").val('')

      // コンテンツ表示
      $("#kxmegane_page3").addClass('hidden').removeClass('js_view');
      $("#kxmegane_page4").addClass('js_view').removeClass('hidden');
    }

    // 戻る
    $("#kxmegane_btn_prev4to3").on("click", function(){
      //コンテンツ表示
      $("#kxmegane_page4").addClass('hidden').removeClass('js_view');
      $("#kxmegane_page3").addClass('js_view').removeClass('hidden');
    });

    var idx = 0;
    var questions = [];
    var answers = [];
    var favoriteQuestions = [];
    var companyName = '';

    // もう一度
    $("#kxmegane_btn_retry").on("click", function(){
      // TOP表示
      $("#kxmegane_page5").addClass('hidden').removeClass('js_view');
      $("#kxmegane_top").addClass('js_view').removeClass('hidden');
    });

    $(function() {
      $("#btn_10").on("click", function(){
        $("#kxmegane_page9").addClass('hidden').removeClass('js_view');
        $("#kxmegane_page10").addClass('js_view').removeClass('hidden');
      })
    });

    //会員登録ページへ遷移
    $("#kxmegane_btn_save").on("click", function(){
      window.location.href = "/public/login";
    });

    // ブラウザバックを制御
    history.pushState(null, null, location.href);
    window.addEventListener("popstate", function (e) {
      if($("#kxmegane_btn_prev").is(':visible')) {
        history.pushState(null, null, null);
        $("#kxmegane_btn_prev").click();
      }else{
        history.back();
      }
    });
  }
});
