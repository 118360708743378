
$(function() {
  if($("#stagecheck").is(':visible')) {
    // 結果送信用変数を定義
    let resultStage = 0;

    const debugMode = false;
    
    /**
     * 初期化処理
     */
    function init() {
      // 初期化処理
      idx = 0;
      // エラー文削除
      $("#question-error").html("");
      $("#question-error2").html("");

      // ボタンを表示
      $("#btnFin").hide();
      $("#btnNext").show();
      
      // チェックボックスのリセット
      $("input[name=answer]:checked").prop("checked",false);
      
      // 得点
      for (var i = 0; i < lifeshift.length; i++) {
        lifeshift[i][1] = 0;
      }
      // 各項目の得点
      for (var i = 0; i < questions.length; i++) {
        questions[i][2] = 0;
      }
      // 問題をシャッフルする。
      questions = shuffle(questions);
      
      // 結果送信用変数を初期化。
      resultStage = 0;
    
    }
    /**
     * 問題表示
     */
    function showQuestion() {
      // プログレスバーの設置
      var bar_width = 100/40 * (idx);
      $("#stagecheck_question-bar").css("width", bar_width + "%");
      
      // 質問の設置
      $("#stagecheck_question-q").html('Q' + (idx+1) + '. ' + questions[idx][0]);
    }
    
    /**
     * index番号を次の番号にして問題表示。
     * 最後の場合は結果表示
     */
    function nextQuestion(animation) {
      if(!selectCheck()){
        // チェックしていない
        return false;
      }
      let blinkSecond = 0;
      let changeSecond = 0;
      if (animation === true && debugMode === false){
        blinkSecond = 400;
        changeSecond = 400;
      }
      // ポイントをセットする
      setPoint();
      
      // indexを次へ
      idx++;

      if(idx == questions.length){
        // 結果表示
        showResult();
      }else{
        // 連打対応のため位置的にクリック無効
        $("input[name=answer],label").css('pointer-events', 'none');
        // 最後の問題
        if(idx == questions.length-1){
          //次へを非表示
          $("#stagecheck_btn_next").removeClass('js_view').addClass('hidden');
          // 自己診断ページへボタンを表示
          $("input[name=answer]").change(function(){
            $("#stagecheck_btn_fin_wrap").addClass('js_view').removeClass('hidden');
          });
        }
        window.setTimeout(function(){
          $("#stagecheck_question-wrap").addClass("stagecheck-page-blink");
          window.setTimeout(function(){
            // チェックボックスのリセット
            checkedCheck();
            // 次の問題
            showQuestion();
            // クリック有効に
            $("input[name=answer],label").css('pointer-events', '');
          }, changeSecond);
        }, blinkSecond);
      }
      if (debugMode === true){
        $('.result').html(questions);
      }
    }
    
    /**
     * index番号を前の番号にして問題表示。
     * 最初の場合はガイダンス表示
     */
    function prevQuestion() {
      // indexを前へ
      idx--;
      // ボタン表示
      $("#stagecheck_btn_fin_wrap").addClass('hidden').removeClass('js_view');
      $("#stagecheck_btn_next").addClass('js_view').removeClass('hidden');
      
      if(idx < 0){
        // ガイダンスページへ
        $("#stagecheck_page2").addClass('hidden').removeClass('js_view');
        $("#stagecheck_page1").addClass('js_view').removeClass('hidden');
      }else{
        // チェックボックスのリセット
        checkedCheck();
        // 問題表示
        showQuestion();
      }
      
    }
    
    /**
     * 戻るを押したときにチェックも戻す
     */
    function checkedCheck(){
      var row = questions[idx];
      var point = row[2];
      if(point > 0){
        if(row[1] == 1){
          // リバースの場合は得点逆にする
          point = 5 - point;
        }
        
        $("input[name=answer]").each(function(){
          var val = $(this).val();
          if(point == val){
            $(this).prop("checked",true);
          }else{
            $(this).prop("checked",false);
          }
        })
      }else{
        $("input[name=answer]:checked").prop("checked",false);
      }
      $(".stagecheck-label-blink").removeClass("stagecheck-label-blink");
      $("#stagecheck_question-wrap").removeClass("stagecheck-page-blink");
    }



    /**
     * セレクトの値をチェックする
     */
    function selectCheck() {
      // エラー文削除
      $("#stagecheck_question-error").html("");
      var is_check = false;
      $("input[name=answer]").each(function(){
        if($(this).prop("checked")){
          is_check = true;
        }
      })
      if(!is_check){
        // エラー文表示
        $("#stagecheck_question-error").html("選択してください。");
      }
      return is_check;
    }
    
    /**
     * ポイントのセット
     */
    function setPoint() {
      var val = $("input[name=answer]:checked").val();
      val = parseInt(val);
      if (questions[idx][1] == 0) {
        questions[idx][2] = val;
      } else {
        questions[idx][2] = 5 - val;
      }
    }

    /**
     * 結果画面の表示
     */
    function showResult() {
      // コンテンツ表示
      $("#stagecheck_page2").addClass('hidden').removeClass('js_view');
      $("#stagecheck_page3").addClass('js_view').removeClass('hidden');

      // ポイントの計算
      calcPointTotal();
      
      // アクセル、ブレーキのTOP3を表示
      showContents();
      
      // サーバーへ通信し、ログを保存
      var params = {
        result_stage: resultStage
      }
      $.ajax({
        type: "POST",
        url: "/stagechecks",
        data: params,
        headers: {
          'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content')
        },
        success: function(msg){
          // NTD
  //        console.log("OK");
  //        console.log(msg);
        },
        error : function(XMLHttpRequest, textStatus, errorThrown){
          console.log(XMLHttpRequest);
          console.log(textStatus);
          console.log(errorThrown);
        }
      });
      
    }
    
    /**
     * 得点の計算
     */
    function calcPointTotal() {
      // 各詳細の得点を整理する
      for (var i = 0; i < questions.length; i++) {
        var point = questions[i][2];
        var ls_num = questions[i][3];
        
        // 合計の計算
        lifeshift[ls_num][1] += questions[i][2];
      }

    }
    
    /**
     * 指定のオブジェクトに結果を表示する
     */
    function showContents(){
      if(lifeshift[0][1]>=34){
        // Aブロックが34以上の場合
        if(lifeshift[1][1]<=5){
          // Bブロックが5以下
          $("#stagecheck_result_txt").html("あなたは「主人公になる」ステージにいます。これまでの経験をフルに生かし、今までの自分のものの考え方やスタイルをアップデートし、気がつけば新たな自分へと変わっています。同時に、このステージは次なるライフシフトの旅が始まる前のステージでもあります。「心の声」に耳を傾け、次なる旅に備えてください。");
          $("#stagecheck_result_img").html('<img src="/stagecheck/img/result_img01.png" alt="BECOME A HERO" class="result_img">');
          resultStage = 1;
          return true;
        }
      }
      
      if(lifeshift[2][1]<=16){
        // Cブロックが16以下
        $("#stagecheck_result_txt").html("あなたは、<strong class='key-color-red-dark1'>「心が騒ぐ」</strong>ステージにいます。自分と向き合い始めていますね。さらに深く、自分が持っている違和感や不安と向き合い、未来を展望する機会を持ってみませんか。そして、次なる「旅に出る」ステージへと進むために、どんなことをしてみようか、考えてみませんか。");
        $("#stagecheck_result_img").html('<img src="/stagecheck/img/result_img02.png" alt="CURIOSITY QUESTION" class="w-2/4 inline-block">');
        resultStage = 2;
        return true;
      }
      
      if(lifeshift[3][1]<=15){
        // Dブロックが15以下
        $("#stagecheck_result_txt").html("あなたは、<strong class='key-color-red-dark1'>「旅に出る」</strong>ステージにいます。変わるために、何かを始めている、とてもいい状態です。その旅を、より質の高いものにするために、ライフシフトの旅に必要な「旅の仲間」との出会いが大切。出会いを求めて、さらに旅を続け、次なる「自分と出会う」ステージへと歩を進めてください。");
        $("#stagecheck_result_img").html('<img src="/stagecheck/img/result_img03.png" alt="GO ON A JOURNEY" class="w-2/4 inline-block">');
        resultStage = 3;
        return true;
      }
      
      if(lifeshift[4][1]<=10){
        // Eブロックが10以下
        $("#stagecheck_result_txt").html("あなたは、<strong class='key-color-red-dark1'>「自分と出会う」</strong>ステージにいます。ライフシフトの方向性がある程度定まってきているようです。その方向性を確かめ、より自分らしい道へと進むために、自信の「価値軸」をしっかりと見つけ出してください。");
        $("#stagecheck_result_img").html('<img src="/stagecheck/img/result_img04.png" alt="MEET MYSELF" class="w-2/4 inline-block">');
        resultStage = 4;
        return true;
      }

      if(lifeshift[5][1]<=10 || lifeshift[6][1]<=7){
        // Fブロックが10以下もしくはGブロックが7以下
        $("#stagecheck_result_txt").html("あなたは<strong class='key-color-red-dark1'>「学びつくす」</strong>ステージにいます。自身の「価値軸」も定まり、それをかたちにしていく階段を上り始めています。その学びの質をより高くしていくために、自身の学び方の振り返りが大切。自分が得意とする学び方に気づくと同時に、新たな学び方にもチャレンジしてみましょう。");
        $("#stagecheck_result_img").html('<img src="/stagecheck/img/result_img05.png" alt="FULL EFFORT" class="w-2/4 inline-block">');
        resultStage = 5;
        return true;
      }
      
      // Gブロックが8以上
      $("#stagecheck_result_txt").html("あなたは<strong class='key-color-red-dark1'>「主人公になる」</strong>ステージにいます。これまでの経験をフルに生かし、今までの自分のものの考え方やスタイルをアップデートし、気がつけば新たな自分へと変わっています。同時に、このステージは次なるライフシフトの旅が始まる前のステージでもあります。「心の声」に耳を傾け、次なる旅に備えてください。");
      $("#stagecheck_result_img").html('<img src="/stagecheck/img/result_img01.png" alt="BECOME A HERO" class="w-2/4 inline-block">');
      resultStage = 1;
      
      return true;
    }

    /**
     * 配列のシャッフル
     */
    function shuffle(arr){
      for (var i = arr.length - 1; i >= 0; i--){

        // 0~iのランダムな数値を取得
        var rand = Math.floor( Math.random() * ( i + 1 ) );

        // 配列の数値を入れ替える
        var temp = arr[i];
        arr[i] = arr[rand];
        arr[rand] = temp;
      }
      
      return arr;
    }
    
    var idx = 0;
    // 0: テキスト、1: リバースフラグ、2:得点、3:ライフシフトindex番号
    var questions = [
      ['社会にとって価値のある仕事をしている。',0,0,0,0 ],
      ['誰かの役に立つ仕事をしている。',0,0,0,1 ],
      ['自身の志向・価値観にあった仕事をしている。',0,0,0,2 ],
      ['自分の能力や経験を活かした仕事をしている。',0,0,0,3 ],
      ['仕事以外で、大切にしたいことを持っている。',0,0,0,4 ],
      ['ワークライフバランスは良好である。',0,0,0,5 ],
      ['今の仕事に満足している。',0,0,0,6 ],
      ['今の生活に満足している。',0,0,0,7 ],
      ['これまで、自分らしい人生を歩んできた。',0,0,0,8 ],
      ['これからの人生を、自分らしく歩んでいける。',0,0,0,9 ],
      
      ['今の状態を続けていていいのかと、気持ちが落ち着かないことがある',0,0,1,10 ],
      ['今の生活のなかには、気になっていることがある。',0,0,1,11 ],
      ['社会の変化に、不安を感じることがある。',0,0,1,12 ],
      
      ['これまでと違うことを、新しく始めてみた。',0,0,2,13 ],
      ['普段出会っている人とは違う人と会うようにしている。',0,0,2,14 ],
      ['セミナーや勉強会に参加するようにしている。',0,0,2,15 ],
      ['地域活動や趣味活動など、新たな活動を始めた。',0,0,2,16 ],
      ['生活のスタイルを、見直し始めている。',0,0,2,17 ],
      ['人生やキャリアに関する相談を誰かにしている。',0,0,2,18 ],
      ['自分が何をしたいのか、よく考えている。',0,0,2,19 ],
      ['何かの機会に、人生やキャリアの振り返りをしたことがある。',0,0,2,20 ],
      
      ['これこそが、自分がやるべきことだ、というテーマが見つかっている。',0,0,3,21 ],
      ['自分が、どういうことをしていると生き生き、ワクワクできるのか、わかっている。',0,0,3,22 ],
      ['自分がやりたいことがあるし、なぜそれをやりたいのか、人に説明できる。',0,0,3,23 ],
      ['子どものころ、熱中したり強く興味を持ったことを、大切にしている。',0,0,3,24 ],
      ['自分が、どういうことに心が動くのか、よくわかっている。',0,0,3,25 ],
      ['自分の信念、信条がはっきりしている。',0,0,3,26 ],
      
      ['大学院やスクールに通っている。',0,0,4,27 ],
      ['自分自身が何を知りたいか、何を学びたいかがはっきりしている。',0,0,4,28 ],
      ['仕事と関係ないことを学び始めた。',0,0,4,29 ],
      ['その道のプロから学んでいる。',0,0,4,30 ],
      ['これまで会ったことがない人とよく会っている。',0,0,4,31 ],
      
      ['この一年で、自分がやりたかったことが実現できた。',0,0,5,32 ],
      ['この一年で、人生を大きく変えることができた。',0,0,5,33 ],
      ['この一年で、望んでいる仕事に就くことができた。',0,0,5,34 ],
      ['この一年で、大切にしていることのために何かを変えた。',0,0,5,35 ],
      ['この一年で、これまでとは全く違う仕事を始めた。',0,0,5,36 ],
      
      ['これまで培ったスキル、ノウハウを今の仕事、働き方に活かしている。',0,0,6,37 ],
      ['今までのやり方、ものの考え方を改めた。',0,0,6,38 ],
      ['自分の時間を自分でマネジメントしている。',0,0,6,39 ]
      
    ];
    // 平均値と標準偏差は固定値 0:group, 1:合計得点2:index番号
    var lifeshift = [
        ['A',0,0],
        ['B',0,1],
        ['C',0,2],
        ['D',0,3],
        ['E',0,4],
        ['F',0,5],
        ['G',0,6]
      ];
    // TOPのボタン処理
    $("#stagecheck_btn_top").on("click", function(){
      $("#stagecheck_top").addClass('hidden').removeClass('js_view');
      $("#stagecheck_page1").addClass('js_view').removeClass('hidden');
    })
    
    // ガイダンスのボタン処理
    $("#stagecheck_btn_start").on("click", function(){
      $("#stagecheck_page1").addClass('hidden').removeClass('js_view');
      // 問題の初期化
      init();
      // 問題の表示
      showQuestion();
      $("#stagecheck_page2").addClass('js_view').removeClass('hidden');
    })
    // チェックボックスのチェック時の処理
    $("input[name=answer]").change(function(){
      $("input[name=answer]").prop("checked", false);
      $(this).prop("checked", true);
      $(this).next('label').addClass('stagecheck-label-blink');
      if(idx < questions.length-1){
        // 最後の問題以外は次の設問へいく
        nextQuestion(true);
      }
    })
    // 次へ
    $("#stagecheck_btn_next").on("click", function(){
      nextQuestion(false);
    });
    // 前へ
    $("#stagecheck_btn_prev").on("click", prevQuestion);
    // 前へ
    $("#stagecheck_btn_prev2").on("click", function(){
      //コンテンツ表示
      $("#stagecheck_page3").addClass('hidden').removeClass('js_view');
      $("#stagecheck_page2").addClass('js_view').removeClass('hidden');
      $("#stagecheck_question-error2").html("");
      prevQuestion();
    });
    
    // 前へ
    $("#stagecheck_btn_prev3").on("click", function(){
      //コンテンツ表示
      $("#stagecheck_page5").addClass('hidden').removeClass('js_view');
      $("#stagecheck_page4").addClass('js_view').removeClass('hidden');
    });
    // 結果
    $("#stagecheck_btn_fin").on("click", function(){
      nextQuestion("false");
    });
    // もう一度
    $("#stagecheck_btn_retry").on("click", function(){
      // TOP表示
      $("#stagecheck_page3").addClass('hidden').removeClass('js_view');
      $("#stagecheck_top").addClass('js_view').removeClass('hidden');
    });
          //会員登録ページへ遷移
          $("#stagecheck_btn_save").on("click", function(){
          window.location.href = "/public/login";
          })

    // ブラウザバックを制御
    history.pushState(null, null, location.href);
    window.addEventListener("popstate", function (e) {
      if($("#stagecheck_btn_prev").is(':visible')) {
        history.pushState(null, null, null);
        $("#stagecheck_btn_prev").click();
      }else{
        history.back();
      }
    });
  }
});


