import Rails from "@rails/ujs"

import "jquery"
import "../stylesheets/public"

const stagecheck = require('./public/stagecheck');
const kxmegane = require('./public/kxmegane');

Rails.start()

$(function() {
  // ユーザーメニューの表示切り替え。
  const toggleSpUserMenu = () => {
    const element1 = $(".aajs-sp-user-menu-target-1");
    const element2 = $(".aajs-sp-user-menu-target-2");
    const element3 = $(".aajs-sp-user-menu-target-3");
    if (element1.hasClass("inactive")) {
      element1.removeClass("inactive");
      element1.show();
      element2.show();
      element3.css('position', 'fixed');
    }
    else {
      element1.addClass("inactive");
      element1.hide();
      element2.hide();
      element3.css('position', 'relative');
    }
  }
  $(".aajs-sp-user-menu-close").on('click', () => {
    toggleSpUserMenu();
  })
  $(".aajs-sp-user-menu-trigger").on('click', () => {
    toggleSpUserMenu();
  })
})


// アセスメントのボタンの効果
$(function() {
  let mainInterval = 150;
  let lastInterval = 300;

  $('.assessment-select-button').on('click', function() {
    $('#updated-dialog').css('display', 'flex');
    let element = $(this);
    let check_mark = element.children('.check-mark');
    let choosedValue = element.data('choosed-value');

    $('#choosed-value').val(choosedValue);

    $('.assessment-select-button').removeClass('assessment-select-button-enable');
    $('.assessment-select-button').addClass('assessment-select-button-disable');
    $('.assessment-select-button').children('.check-mark').removeClass('check-mark-enable');
    $('.assessment-select-button').children('.check-mark').addClass('check-mark-disable');

    element.removeClass('assessment-select-button-disable');
    element.addClass('assessment-select-button-enable');
    element.addClass('assessment-select-button-blink');
    check_mark.removeClass('check-mark-disable');
    check_mark.addClass('check-mark-enable');

    setTimeout(function() {
      element.removeClass('assessment-select-button-blink');

      setTimeout(function() {
        element.addClass('assessment-select-button-blink');

        setTimeout(function() {
          element.removeClass('assessment-select-button-blink');

          setTimeout(function() {
            let element2 = $('.assessment_content');
            element2.css('opacity', 0);
            element2.css('transform', 'translateY(-500px)');
            $('#assessment-form').submit();
          }, lastInterval)

        }, mainInterval)
      }, mainInterval)
    }, mainInterval)
  });
});


// 複数選択の場合のアセスメントの回答ボタンの効果
$(function() {
  $('.assessment-submit-button').on('click', function() {
    $('#assessment-form').submit();
  });
});

$(function() {
  $('.assessment-checkbox').on('click', function() {
    let assessmentInputField = $(this).children('.assessment-input-field');
    if (assessmentInputField.val() == "false") {
      assessmentInputField.val("true");
      $(this).removeClass('assessment-select-button-disable');
      $(this).addClass('assessment-select-button-enable');
      $(this).children('.check-mark').removeClass('check-mark-disable');
      $(this).children('.check-mark').addClass('check-mark-enable');
    }
    else {
      assessmentInputField.val("false");
      $(this).removeClass('assessment-select-button-enable');
      $(this).addClass('assessment-select-button-disable');
      $(this).children('.check-mark').removeClass('check-mark-enable');
      $(this).children('.check-mark').addClass('check-mark-disable');
    }
  });
});

$(function() {
  let element = $('.previous-page-transition');
  element.css('opacity', 1);
  element.css('transform', 'translateY(0)');

  $('.previous_page').on('click', function(e) {
    element.css('opacity', 0);
    element.css('transform', 'translateY(500px)');

    e.preventDefault();
    $(this).closest('form').submit();
    return false;
  });
});

$(function() {
  let element = $('.next-page-transition');
  element.css('opacity', 1);
  element.css('transform', 'translateY(0)');

  $('.next_page').on('click', function(e) {
    element.css('opacity', 0);
    element.css('transform', 'translateY(-500px)');

    e.preventDefault();
    $(this).closest('form').submit();
    return false;
  });

  $('.next_page_updated').on('click', function(e) {
    $('#updated-dialog').css('display', 'flex');

    element.css('opacity', 0);
    element.css('transform', 'translateY(-500px)');

    e.preventDefault();
    $(this).closest('form').submit();
    return false;
  });
});

$(function() {
  let assessmentStartForm = $('#assessment_start_form');
  if (assessmentStartForm) {
    assessmentStartForm.submit(function() {
      let assessmentStartButton = $('#assessment_start_button');
      if (assessmentStartButton) {
        assessmentStartButton.attr('disabled', 'true');
        assessmentStartButton.addClass('cursor-wait');
      }
    })
  }
});
